import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Earthday2020Page = ({ location }) => (
  <Layout location={location} title="Earthday 2020">
    <SEO title="Earthday 2020" />
    <section id="earthday-page" className="page">
      <div className="container text-white">
        <div className="content bg-black p-5">
          <p>
            Join us in raising awareness and contributing to the 50th
            Anniversary of Earth Day in April 2020. This is a Global Celebration
            of our interconnectedness and interdependency with all living things
            aimed at raising awareness of the threatened and disappearing
            ecosystems which support life as we know it.
          </p>
          <p>
            It is a worldwide gathering of many millions of people around the
            world addressing important issues at every local, national and
            global level. We need to focus on solving the challenges we are
            faced with while remembering to reconnect to the vibrancy of our
            living home planet today – sharing Earth is our common ground.
          </p>
          <img src="/assets/img/Group-Product-Pano.jpg" width="100%" alt="group product pano" />
          <p>
            Global View Foundation in the year 2000 was called the Spaceship
            Earth Foundation, and Earth Day was celebrating its 30th Anniversary
            that April. One of our main programs since we started a few years
            prior was to establish an annual Earth Day event in our home
            community of Colorado Springs. This was the year the City’s Annual
            Celebration begun with a formal Mayoral Proclamation and
            solar-powered concert and booth event in our downtown Park.
          </p>
          <p>
            We look forward to seeing what Colorado Springs has planned for the
            2020 Earth Day Celebration. Check back here for updates.
          </p>
          <h2 className="my-3">Earth Challenge 2020</h2>
          <h3>Earth Challenge 2020 – a Citizen Science Initiative</h3>
          <p>
            What is Earth Challenge 2020? April 22, 2020 marks the 50th
            anniversary of Earth Day. In recognition of this milestone Earth Day
            Network, the Woodrow Wilson International Center for Scholars, and
            the U.S. Department of State’s Eco-Capitals Forum are launching
            Earth Challenge 2020 as the world’s largest coordinated citizen
            science campaign.
          </p>
          <p>
            Earth Challenge 2020 will engage millions of global citizens to
            aggregate and collect more than one billion data points in areas
            including air quality, water quality, biodiversity, pollution, and
            human health. Through Earth Challenge 2020, citizen science
            volunteers will learn about their local conditions, and leverage
            information to inspire collaborative action and influence policy
            decisions. To find out more, visit{" "}
            <p>
              <a
                href="https://www.earthday.org/campaigns/earthchallenge2020/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-lime-green"
              >
                EarthDay.org
              </a>
            </p>

            <div className="d-flex justify-content-start align-items-center mt-5">
                  <Link className="btn btn-donate hvr-shadow" to="/contact">
                    FINE ART FUNDRAISING SIGN UP
                    <span>to receive resources</span>
                  </Link>
                </div>
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default Earthday2020Page
